import { BrowserRouter, Routes, Route } from "react-router-dom";

import Error from "./Pages/404/Error";
import Login from "./Pages/Login/Login";
import Users from "./Pages/Users/Users";
import Update from "./Pages/Update/Update";
import ProtectedRoute from "./ProtectedRoute";
import AddData from "./Pages/Post Data/AddData";
import Register from "./Pages/Register/Register";
import Dashboard from "./Pages/Dashboard/Dashboard";
import Profile from "./Components/User/Profile/Profile";
import ActiveData from "./Pages/Active Data/ActiveData";
import AccountDetails from "./Pages/Details/AccountDetails";
import InactiveData from "./Pages/Inactive Data/InactiveData";
import PaginatorBasicDemo from "./Features/Context/Pagination";
import UserDetails from "./Components/User/UserDetails/UserDetails";
import RetiredData from "./Pages/Retired Data/RetiredData";
import AddInventory from "./Pages/Add Inventory/Add_Inventory";
import InventoryData from "./Pages/Inventory/inventoryData";
import InventoryDetails from "./Pages/Inventory Details/inventory-Details";
import ForgotPassword from "./Pages/Password/ForgotPassword";
import ResetPassword from "./Pages/Password/Reset_Password";
import EditDetails from "./Pages/Edit/EditDetails";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<Login />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route element={<ProtectedRoute />}>
          <Route path="/" element={<Dashboard />} />
          <Route path="/settings" element={<Users />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/add-license" element={<AddData />} />
          <Route path="/edit-license" element={<Update />} />
          <Route path="/try" element={<PaginatorBasicDemo />} />
          <Route path="/inventory" element={<InventoryData />} />
          <Route path="/user-details" element={<UserDetails />} />
          <Route path="/license/active" element={<ActiveData />} />
          <Route path="/add-inventory" element={<AddInventory />} />
          <Route
            path="/view-inventory-details"
            element={<InventoryDetails />}
          />
          <Route path="/view-details" element={<AccountDetails />} />
          <Route path="/edit" element={<EditDetails />} />
          <Route path="/license/retired" element={<RetiredData />} />
          <Route path="/license/inactive" element={<InactiveData />} />
        </Route>
        <Route path="*" element={<Error />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
