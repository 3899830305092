import axios from "axios";
import { Toast } from "primereact/toast";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { InputText } from "primereact/inputtext";
import { DataTable } from "primereact/datatable";
import { IoEyeSharp } from "react-icons/io5";
import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

const DashboardTable = () => {
  const dt = useRef(null);
  const toast = useRef(null);
  const [all_data, setAllData] = useState(null);
  const [globalFilter, setGlobalFilter] = useState(null);

  useEffect(() => {
    const token = sessionStorage.getItem("token");
    let subscription = true;

    if (token) {
      axios
        .get(`${process.env.REACT_APP_Base_Url}/license/all_license`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (res.data.status === 200 && subscription) {
            // console.log(res.data);
            // the below line is the one that updates the state
            setAllData(res.data.info);
          }
        })
        .catch((err) => console.log(err));
    }

    return () => (subscription = false);
  }, []);

  const exportCSV = () => {
    dt.current.exportCSV();
  };

  const rightToolbarTemplate = () => {
    return (
      <Button
        label="Export"
        icon="pi pi-upload"
        className="p-button-help"
        onClick={exportCSV}
      />
    );
  };

  const header = (
    <div
      className="flex-wrap gap-2 align-items-center justify-content-between"
      style={{ display: "flex" }}>
      <h4 className="m-0">Manage License</h4>
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          onInput={(e) => setGlobalFilter(e.target.value)}
          placeholder="Search..."
        />
      </span>
    </div>
  );

  return (
    <>
      <Toast ref={toast} />

      <div className="container-xxl flex-grow-1 container-p-y">
        {all_data && (
          <div className="card">
            <Toolbar className="mb-4" right={rightToolbarTemplate}></Toolbar>

            <DataTable
              ref={dt}
              value={all_data}
              dataKey="_id"
              paginator
              rows={10}
              rowsPerPageOptions={[5, 10, 25]}
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords} License"
              globalFilter={globalFilter}
              header={header}>
              <Column body="#" header="#"></Column>
              <Column
                field="company_name"
                header="Company Name"
                sortable></Column>
              <Column
                body={(rowData) => (
                  <span>
                    {new Date(rowData.activation_date).toLocaleDateString(
                      "en-US",
                      {
                        dateStyle: "long",
                      }
                    )}
                  </span>
                )}
                header="Activation Date"
                sortable></Column>
              <Column
                header="Expiration Date"
                body={(rowData) => (
                  <span>
                    {new Date(rowData.expired_date).toLocaleDateString(
                      "en-US",
                      {
                        dateStyle: "long",
                      }
                    )}
                  </span>
                )}
                sortable></Column>
              <Column
                header="Status"
                body={(rowData) => (
                  <>
                    {rowData.license_status === "active" ? (
                      <button className="btn btn-success">
                        {rowData.license_status}
                      </button>
                    ) : (
                      <button className="btn btn-danger">
                        {rowData.license_status}
                      </button>
                    )}
                  </>
                )}></Column>
              <Column
                field="expiration_count"
                header="Days Left"
                sortable></Column>
              <Column
                header="Action"
                body={(rowData) => (
                  <>
                    <Link
                      to={`/view-details`}
                      className="text-black p-1"
                      state={rowData?._id}>
                      <IoEyeSharp size={26} color="orange" />
                    </Link>
                  </>
                )}></Column>
            </DataTable>
          </div>
        )}
      </div>
    </>
  );
};

export default DashboardTable;
