import React from "react";
import { useSelector } from "react-redux";

const Content = () => {
  // calling the user from the redux store
  const { user } = useSelector((state) => state.user);

  return (
    <>
      <div className="container-xxl flex-grow-1 container-p-y">
        <div className="row">
          <div className="col-md-12">
            <div className="card mb-4">
              <h5 className="card-header">Profile Details</h5>
              {/* Account */}

              <hr className="my-0" />
              <div className="card-body">
                {user && (
                  <form>
                    <div className="row">
                      <div className="mb-3 col-md-6">
                        <label htmlFor="firstName" className="form-label">
                          First Name
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          name="firstName"
                          placeholder={user.first_name}
                        />
                      </div>
                      <div className="mb-3 col-md-6">
                        <label htmlFor="lastName" className="form-label">
                          Last Name
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          name="lastName"
                          placeholder={user.last_name}
                        />
                      </div>
                      <div className="mb-3 col-md-6">
                        <label htmlFor="email" className="form-label">
                          E-mail
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          name="email"
                          placeholder={user.email}
                        />
                      </div>
                      <div className="mb-3 col-md-6">
                        <label htmlFor="organization" className="form-label">
                          role
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="role"
                          placeholder={user.user_role}
                        />
                      </div>
                    </div>
                    {/* <div className="mt-2">
                      <button type="submit" className="btn btn-primary me-2">
                        Update changes
                      </button>
                      <button
                        type="reset"
                        className="btn btn-outline-secondary"
                        onClick={() => window.location.reload(true)}>
                        Cancel
                      </button>
                    </div> */}
                  </form>
                )}
              </div>
              {/* /Account */}
            </div>
            <div className="card">
              <h5 className="card-header">Delete Account</h5>
              <div className="card-body">
                <div className="mb-3 col-12 mb-0">
                  <div className="alert alert-warning">
                    <h6 className="alert-heading fw-bold mb-1">
                      Are you sure you want to delete your account?
                    </h6>
                    <p className="mb-0">
                      Once you delete your account, there is no going back.
                      Please be certain.
                    </p>
                  </div>
                </div>
                <form>
                  <div className="form-check mb-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="accountActivation"
                      id="accountActivation"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="accountActivation">
                      I confirm my account deactivation
                    </label>
                  </div>
                  <button
                    type="submit"
                    className="btn btn-danger deactivate-account">
                    Deactivate Account
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Content;
