import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "USER",
  initialState: {
    user: {},
    status_change: [],
    delete_change: [],
    users_change: [],
    dashboard_stats: [],
    all_data: [],
    product_filter: [],
    inventory_filter: [],
  },
  reducers: {
    setUserInfo: (state, action) => {
      state.user = action.payload;
    },
    updateStatusInfo: (state, action) => {
      state.status_change = action.payload;
    },
    updateDeleteStatus: (state, action) => {
      state.delete_change = action.payload;
    },
    updateUsersChange: (state, action) => {
      state.users_change = action.payload;
    },
    updateDashboard: (state, action) => {
      state.dashboard_stats = action.payload;
    },
    setAllData: (state, action) => {
      state.all_data = action.payload;
    },
    setProductFilter: (state, action) => {
      state.product_filter = action.payload;
    },
    setInventory: (state, action) => {
      state.inventory_filter = action.payload;
    },
  },
});

export const {
  setUserInfo,
  updateStatusInfo,
  updateDeleteStatus,
  updateUsersChange,
  updateDashboard,
  setAllData,
  setProductFilter,
  setInventory,
} = userSlice.actions;
export default userSlice.reducer;
