import axios from "axios";
import { Toast } from "primereact/toast";
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateStatusInfo } from "../../Redux/slicies/userSlice";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { MdCancel } from "react-icons/md";

const ActiveTable = () => {
  const dispatch = useDispatch();
  const toast = useRef<Toast>(null);

  const { status_change } = useSelector(
    (state: { user: { status_change: any } }) => state.user
  );

  const reject = () => {
    show("You have rejected", 400);
  };

  const confirm1 = (id: string) => {
    confirmDialog({
      message: "Do you want to deactivate this record?",
      header: "Deactivate Confirmation",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-danger",
      accept: () => Deactivate(id),
      reject,
    });
  };

  const Deactivate = async (id: string) => {
    await axios
      .post(
        `${process.env.REACT_APP_Base_Url}/license/deactivate_license`,
        {
          license_id: id,
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        show(res.data.message, res.data.status);

        axios
          .get(`${process.env.REACT_APP_Base_Url}/license/active`, {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
          })
          .then((res) => {
            if (res.data.status === 200) {
              // the below line is the one that updates the state
              dispatch(updateStatusInfo(res.data.info));
            }
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_Base_Url}/license/active`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status === 200) {
          // the below line is the one that updates the state
          dispatch(updateStatusInfo(res.data.info));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [dispatch]);

  // todo:@ call primereact toast
  const show = (message: string, flag: number) => {
    toast.current?.show({
      severity: flag === 200 ? "info" : "warn",
      summary: flag === 200 ? "info" : "warn",
      detail: message,
      life: 15000,
    });
  };

  return (
    <>
      <Toast ref={toast} />
      <ConfirmDialog />

      <div className="container-xxl flex-grow-1 container-p-y">
        <h4 className="fw-bold py-3 mb-4">
          <span className="text-muted fw-light">Tables /</span> Active License
          Table
        </h4>
        {/* Basic Bootstrap Table */}
        <div className="card">
          <h5 className="card-header">Active Table </h5>
          <div className="table-responsive text-nowrap">
            <table className="table">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Account</th>
                  <th>Date Activated</th>
                  <th>Expiration Date</th>
                  <th>Days Left</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>

              {status_change &&
              status_change.length !== 0 &&
              status_change.length !== undefined ? (
                <tbody className="table-border-bottom-0">
                  {status_change &&
                    status_change.map((license: any, index: number) => (
                      <tr key={license?._id}>
                        <td>{index + 1}</td>
                        <td>
                          <i className="fab fa-angular fa-lg text-danger me-3" />{" "}
                          <strong>{license?.company_name}</strong>
                        </td>
                        <td>
                          {new Date(
                            license?.activation_date
                          ).toLocaleDateString("en-US", {
                            dateStyle: "long",
                          })}
                        </td>
                        <td>
                          {new Date(license?.expired_date).toLocaleDateString(
                            "en-US",
                            {
                              dateStyle: "long",
                            }
                          )}
                        </td>
                        <td>
                          {license?.expiration_count} {""} Day(s)
                        </td>
                        <td>
                          <span className="badge bg-label-primary me-1">
                            {license?.license_status}
                          </span>
                        </td>
                        <td>
                          <button
                            type="button"
                            className="p-2"
                            title="Deactivate"
                            onClick={(e) => confirm1(license?._id)}>
                            <MdCancel size={30} color="red" />
                          </button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              ) : (
                <tbody>
                  <tr>
                    <th></th>
                    <td></td>
                    <td className="py-3">No Records Found</td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                </tbody>
              )}
            </table>
          </div>
        </div>
        {/*/ Basic Bootstrap Table */}
        <hr className="my-5" />
      </div>
    </>
  );
};

export default ActiveTable;
