import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { updateDeleteStatus } from "../../Redux/slicies/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Link } from "react-router-dom";
import axios from "axios";
import { MdDeleteForever } from "react-icons/md";
import { FaRegEdit } from "react-icons/fa";
import { FaExpeditedssl } from "react-icons/fa6";

const InactiveTable = () => {
  const dispatch = useDispatch();
  const toast = useRef<Toast>(null);
  interface RootState {
    user: {
      delete_change: any;
    };
  }

  const { delete_change } = useSelector((state: RootState) => state.user);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_Base_Url}/license/inactive`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status === 200) {
          //TODO: the below line is the one that updates the state
          dispatch(updateDeleteStatus(res.data.info));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [dispatch]);

  const reject = () => {
    show("You have rejected", 400);
  };

  // trigger for deleting an item
  const confirm = (id: string) => {
    confirmDialog({
      message: "Do you want to delete this item?",
      header: "Delete Confirmation",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-danger",
      accept: () => DeleteItem(id),
      reject,
    });
  };

  // trigger for retiring an item
  const confirm1 = (id: string) => {
    confirmDialog({
      message: "Do you want to Retire this item?",
      header: "Retire Confirmation",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-warning",
      accept: () => RetireItem(id),
      reject,
    });
  };

  // Delete License Logic
  const DeleteItem = async (id: string) => {
    await axios
      .post(
        `${process.env.REACT_APP_Base_Url}/license/delete_license`,
        {
          license_id: id,
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        show(res.data.message, res.data.status);

        // todo:@ inactive api call
        axios
          .get(`${process.env.REACT_APP_Base_Url}/license/inactive`)
          .then((res) => {
            if (res.data.status === 200) {
              //TODO: the below line is the one that updates the state
              dispatch(updateDeleteStatus(res.data.info));
            }
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Retire License Logic
  const RetireItem = async (id: string) => {
    await axios
      .post(
        `${process.env.REACT_APP_Base_Url}/license/retire_license`,
        {
          license_id: id,
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        show(res.data.message, res.data.status);
        axios
          .get(`${process.env.REACT_APP_Base_Url}/license/inactive`)
          .then((res) => {
            if (res.data.status === 200) {
              //TODO: the below line is the one that updates the state
              dispatch(updateDeleteStatus(res.data.info));
            }
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // todo:@ call primereact toast
  const show = (message: string, flag: number) => {
    toast.current?.show({
      severity: flag === 200 ? "info" : "warn",
      summary: flag === 200 ? "info" : "warn",
      detail: message,
      life: 15000,
    });
  };

  return (
    <>
      <Toast ref={toast} />
      <ConfirmDialog />

      <div className="container-xxl flex-grow-1 container-p-y">
        <h4 className="fw-bold py-3 mb-4">
          <span className="text-muted fw-light">Tables /</span> Inactive License
          Table
        </h4>
        {/* Basic Bootstrap Table */}
        <div className="card">
          <h5 className="card-header">Inactive Table </h5>
          <div className="table-responsive text-nowrap">
            <table className="table">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Account</th>
                  <th>Date Activated</th>
                  <th>Expiration Date</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>

              {delete_change &&
              delete_change.length !== 0 &&
              delete_change.length !== undefined ? (
                <tbody className="table-border-bottom-0">
                  {delete_change &&
                    delete_change.map((license: any, index: number) => (
                      <tr key={license?._id}>
                        <td>{index + 1}</td>
                        <td>
                          <i className="fab fa-angular fa-lg text-danger me-3" />{" "}
                          <strong>{license?.company_name}</strong>
                        </td>
                        <td>
                          {new Date(
                            license?.activation_date
                          ).toLocaleDateString("en-US", {
                            dateStyle: "long",
                          })}
                        </td>
                        <td>
                          {new Date(license?.expired_date).toLocaleDateString(
                            "en-US",
                            {
                              dateStyle: "long",
                            }
                          )}
                        </td>

                        <td>
                          <span className="badge bg-label-primary me-1">
                            {license?.license_status}
                          </span>
                        </td>
                        <td>
                          <span className="px-1">
                            <button
                              type="button"
                              className="btn p-2"
                              title="Delete"
                              onClick={() => confirm(license?._id)}>
                              <MdDeleteForever size={30} color="red" />
                            </button>
                          </span>

                          <span className="px-1">
                            <button
                              type="button"
                              title="Retire"
                              className="btn p-2"
                              onClick={() => confirm1(license?._id)}>
                              <FaExpeditedssl size={30} color="orange" />
                            </button>
                          </span>

                          <span className="px-1">
                            <Link
                              to={`/edit-license`}
                              state={license?._id}
                              title="Edit"
                              className="btn p-2">
                              <FaRegEdit size={30} color="green" />
                            </Link>
                          </span>
                        </td>
                      </tr>
                    ))}
                </tbody>
              ) : (
                <tbody>
                  <tr>
                    <th></th>
                    <td></td>
                    <td className="py-3">No Records Found</td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                </tbody>
              )}
            </table>
          </div>
        </div>
        {/*/ Basic Bootstrap Table */}
        <hr className="my-5" />
      </div>
    </>
  );
};

export default InactiveTable;
