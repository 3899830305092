import { useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import axios from "axios";

const UpdateForm = () => {
  const toast = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [state, setState] = useState(null);
  console.log(location);

  useEffect(() => {
    axios
      .post(
        `${process.env.REACT_APP_Base_Url}/license/license_details`,
        {
          license_id: location.state,
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        setState(res.data.info);
      })
      .catch((err) => console.log(err));
  }, [location.state]);

  const handleSubmit = (e) => {
    e.preventDefault();

    const new_activation_date = new Date(state.activation_date);
    const new_expired_date = new Date(state.expired_date);

    console.log(new_expired_date, new_activation_date);

    axios
      .post(
        `${process.env.REACT_APP_Base_Url}/license/update_license`,
        {
          license_id: location.state,
          account_code: state.account_code,
          company_name: state.company_name,
          company_email: state.company_email,
          activation_date: state.activation_date,
          expired_date: state.expired_date,
          payment_date: state.payment_date,
          contact: state.contact,
          comment: state.comment,
          region: state.region,
          location: state.location,
          branches: state.branches,
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        if (res.data.status === 200) {
          show(res.data.message, res.data.status);

          setTimeout(() => {
            navigate("/license/inactive");
          }, 2000);
        } else {
          show(res.data.message, res.data.status);
        }
      })
      .catch((err) => console.log(err));
  };

  // todo:@ call primereact toast
  const show = (message, flag) => {
    toast.current?.show({
      severity: flag === 200 ? "info" : "warn",
      summary: flag === 200 ? "info" : "warn",
      detail: message,
      life: 15000,
    });
  };

  return (
    <>
      <Toast ref={toast} />

      <div className="container-xxl flex-grow-1 container-p-y">
        <h4 className="fw-bold py-3 mb-4">
          <span className="text-muted fw-light">License </span> Renewal
        </h4>
        {/* Basic Layout & Basic with Icons */}
        <div className="row">
          {/* Basic with Icons */}
          <div className="col-xxl">
            <div className="card mb-4">
              <div
                className="card-header d-flex align-items-center justify-content-between"
                style={{
                  // backgroundColor: "white",
                  borderBottom: "1px solid #e0e0e0",
                  // padding: "0 1rem",
                }}>
                {state && (
                  <>
                    <h4 className="mb-0">
                      License Renewal for
                      <b> {state.company_name}</b>
                    </h4>
                  </>
                )}
              </div>

              {state && (
                <div className="card-body">
                  <form onSubmit={handleSubmit}>
                    <div className="row pt-3 mb-3">
                      <label className="col-sm-2 col-form-label">
                        Account Code
                      </label>
                      <div className="col-sm-10">
                        <div className="input-group input-group-merge">
                          <input
                            type="text"
                            className="form-control"
                            disabled
                            defaultValue={state.account_code}
                            aria-describedby="basic-icon-default-fullname2"
                            onChange={(e) =>
                              setState((state) => {
                                return {
                                  ...state,
                                  account_code: e.target.value,
                                };
                              })
                            }
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row mb-3">
                      <label
                        className="col-sm-2 col-form-label"
                        htmlFor="basic-icon-default-company">
                        Email
                      </label>
                      <div className="col-sm-10">
                        <div className="input-group input-group-merge">
                          <input
                            type="text"
                            className="form-control"
                            disabled
                            defaultValue={state.company_email}
                            aria-describedby="basic-icon-default-company2"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row mb-3">
                      <label
                        className="col-sm-2 col-form-label"
                        htmlFor="basic-icon-default-email">
                        Activation Date
                      </label>
                      <div className="col-sm-10">
                        <div className="input-group input-group-merge">
                          <input
                            type="date"
                            className="form-control"
                            name="activation_date"
                            id="activation_date"
                            // defaultValue={state.activation_date}
                            onChange={(e) =>
                              setState((state) => {
                                return {
                                  ...state,
                                  activation_date: e.target.value,
                                };
                              })
                            }
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row mb-3">
                      <label className="col-sm-2 form-label">
                        Expiration Date
                      </label>
                      <div className="col-sm-10">
                        <div className="input-group input-group-merge">
                          <input
                            type="date"
                            name="expired_date"
                            id="expired_date"
                            className="form-control phone-mask"
                            // defaultValue={state.expired_date}
                            onChange={(e) =>
                              setState((state) => {
                                return {
                                  ...state,
                                  expired_date: e.target.value,
                                };
                              })
                            }
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row mb-3">
                      <label className="col-sm-2 form-label">
                        Payment Date
                      </label>
                      <div className="col-sm-10">
                        <div className="input-group input-group-merge">
                          <input
                            type="date"
                            name="payment_date"
                            id="payment_date"
                            className="form-control phone-mask"
                            // defaultValue={state.payment_date}
                            onChange={(e) =>
                              setState((state) => {
                                return {
                                  ...state,
                                  payment_date: e.target.value,
                                };
                              })
                            }
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row mb-3">
                      <label
                        className="col-sm-2 form-label"
                        htmlFor="basic-icon-default-phone">
                        Comment
                      </label>
                      <div className="col-sm-10">
                        <div className="input-group input-group-merge">
                          <textarea
                            className="form-control"
                            defaultValue={state.comment}
                            onChange={(e) =>
                              setState((state) => {
                                return {
                                  ...state,
                                  comment: e.target.value,
                                };
                              })
                            }></textarea>
                        </div>
                      </div>
                    </div>

                    <div className="row justify-content-end">
                      <div className="col-sm-10">
                        <button type="submit" className="btn btn-primary">
                          Renew License
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdateForm;
