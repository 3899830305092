import React from "react";
import Header from "../../Views/Header";
import Footer from "../../Views/Footer";
import Navbar from "../../Navbar/Navbar";
import Content from "./Content";

const Profile = () => {
  return (
    <>
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          <Header />
          <div className="layout-page">
            <Navbar />
            <div className="content-wrapper">
              <Content />
              <Footer />
              <div className="content-backdrop fade" />
            </div>
          </div>
        </div>
        <div className="layout-overlay layout-menu-toggle" />
      </div>
    </>
  );
};

export default Profile;
