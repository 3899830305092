import LoginStyles from "../Login/Login.module.css";
import { useNavigate } from "react-router-dom";
import { useState, useRef } from "react";
import { Toast } from "primereact/toast";
import axios from "axios";

const ResetPassword = () => {
  const toast = useRef<Toast>(null);
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [confirm_password, setConfirmPassword] = useState("");
  const [loader, setLoader] = useState(true);

  // get token from url as a search param
  const token = window.location.search.split("=")[1];
  console.log(token);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setLoader(false);
    if (password !== confirm_password)
      return show("Password does not match", 400);

    axios
      .post(`${process.env.REACT_APP_Base_Url}/admin/reset_password`, {
        password,
        token,
      })
      .then((res) => {
        show(res.data.message, res.data.status);

        if (res.data.status === 200) {
          setTimeout(() => {
            navigate("/");
          }, 2000);
        }
        setLoader(true);
      })
      .catch((err) => {
        show(err.data.message, err.data.status);
        setLoader(true);
      });
  };

  // todo:@ call toast
  const show = (message: string, flag: number) => {
    toast.current?.show({
      severity: flag === 200 ? "success" : "error",
      summary: flag === 200 ? "success" : "error",
      detail: message,
      life: 15000,
    });
  };

  return (
    <>
      <Toast ref={toast} />

      <div className="container-xxl">
        <div
          className="authentication-wrapper authentication-basic container-p-y"
          style={{ height: "100vh" }}>
          <div className="authentication-inner" id={LoginStyles.motherDisplay}>
            <div className="card" id={LoginStyles.innerDisplay}>
              <div className="card-body">
                <h4 className="mb-2">Password Reset Page </h4>
                <p className="mb-4">
                  Please reset your account password and start the adventure
                </p>
                <form className="mb-3" onSubmit={handleSubmit}>
                  <div className="mb-3">
                    <label htmlFor="email" className="form-label">
                      Password
                    </label>
                    <input
                      type="password"
                      className="form-control"
                      name="password"
                      required
                      placeholder="Enter your password"
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                  <div className="mb-5 form-password-toggle">
                    <div className="d-flex justify-content-between">
                      <label className="form-label" htmlFor="confirm_password">
                        Confirm Password
                      </label>
                    </div>
                    <div className="input-group input-group-merge">
                      <input
                        type="password"
                        className="form-control"
                        name="confirm_password"
                        placeholder="Confirm your password"
                        aria-describedby="confirm_password"
                        required
                        onChange={(e) => setConfirmPassword(e.target.value)}
                      />
                      <span className="input-group-text cursor-pointer">
                        <i className="bx bx-hide" />
                      </span>
                    </div>
                  </div>

                  {loader ? (
                    <div className="mb-3">
                      <button
                        className="btn btn-primary d-grid w-100"
                        type="submit">
                        Sign in
                      </button>
                    </div>
                  ) : (
                    <div className="mb-3">
                      <button
                        disabled
                        className="btn btn-primary d-grid w-100"
                        type="submit">
                        loading ...
                      </button>
                    </div>
                  )}
                </form>
                <p className="text-center">
                  <span>New on our platform ? </span> {""}
                  <a href="login">
                    <span style={{ color: "green" }}>login</span>
                  </a>{" "}
                  |
                  <a href="/forgot-password">
                    <span style={{ color: "red" }}> forgot password</span>
                  </a>
                </p>
              </div>
            </div>
            {/* /Register */}
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
