import React from "react";

const Footer = () => {
  const correctDate = new Date().getFullYear();

  return (
    <>
      <footer className="content-footer footer bg-footer-theme">
        <div className="container-xxl d-flex flex-wrap justify-content-between py-2 flex-md-row flex-column">
          <div className="mb-2 mb-md-0">Sanfiley @ {correctDate}.</div>
          <div>
            <a
              href="https://github.com/flexywork327/records.git"
              target="_blank"
              rel="noreferrer"
              className="footer-link me-4">
              Documentation
            </a>
            <a
              href="https://resumex.vercel.app/"
              target="_blank"
              rel="noreferrer"
              className="footer-link me-4">
              Support
            </a>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
