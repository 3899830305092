import { updateDashboard } from "../../Redux/slicies/userSlice";
import { useDispatch, useSelector } from "react-redux";
import DashboardTable from "./DashboardTable";
import { useEffect } from "react";
import axios from "axios";

const Content = () => {
  const dispatch = useDispatch();
  interface RootState {
    user: {
      dashboard_stats: any;
    };
  }

  const { dashboard_stats } = useSelector((state: RootState) => state.user);

  useEffect(() => {
    const token = sessionStorage.getItem("token");

    let isLoaded = false;

    if (token) {
      axios
        .get(`${process.env.REACT_APP_Base_Url}/license/dashboard`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (res.data.status === 200 && !isLoaded) {
            // the below line is the one that updates the state
            dispatch(updateDashboard(res.data.info));
          }
        })
        .catch((err) => console.log(err));
    }

    return () => {
      isLoaded = true;
    };
  }, [dispatch]);

  return (
    <>
      <div className="container-xxl flex-grow-1 container-p-y">
        <div className="row">
          <div className="col-lg-12 col-md-12 order-1">
            {dashboard_stats && (
              <div className="row">
                <div className="col-lg-4 col-md-4 col-12 mb-4">
                  <div className="card">
                    <div className="card-body m-3">
                      <h4 className="fw-semibold d-block mb-2">
                        Total Accounts{" "}
                        <span className="card-title px-3">
                          {dashboard_stats?.totalLicense}
                        </span>
                      </h4>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-4 col-12 mb-4">
                  <div className="card">
                    <div className="card-body m-3">
                      <h4 className="fw-semibold d-block mb-2">
                        Active License{" "}
                        <span className="card-title px-3">
                          {dashboard_stats?.activeLicense}
                        </span>
                      </h4>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-4 col-12 mb-4">
                  <div className="card">
                    <div className="card-body m-3">
                      <h4 className="fw-semibold d-block mb-2">
                        Retired License{" "}
                        <span className="card-title px-3">
                          {dashboard_stats?.inactiveLicense}
                        </span>
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="row">
          <DashboardTable />
        </div>
      </div>
    </>
  );
};

export default Content;
