import { useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import axios from "axios";

const UpdateForm = () => {
  const toast = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [state, setState] = useState(null);
  console.log(location);

  useEffect(() => {
    axios
      .post(
        `${process.env.REACT_APP_Base_Url}/license/license_details`,
        {
          license_id: location.state,
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        setState(res.data.info);
      })
      .catch((err) => console.log(err));
  }, [location.state]);

  const handleSubmit = (e) => {
    e.preventDefault();

    axios
      .post(
        `${process.env.REACT_APP_Base_Url}/license/edit_account_details`,
        {
          license_id: location.state,
          account_code: state.account_code,
          company_name: state.company_name,
          company_email: state.company_email,
          contact: state.contact,
          region: state.region,
          location: state.location,
          branches: state.branches,
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        if (res.data.status === 200) {
          show(res.data.message, res.data.status);

          setTimeout(() => {
            navigate("/");
          }, 2000);
        } else {
          show(res.data.message, res.data.status);
        }
      })
      .catch((err) => console.log(err));
  };

  // todo:@ call primereact toast
  const show = (message, flag) => {
    toast.current?.show({
      severity: flag === 200 ? "info" : "warn",
      summary: flag === 200 ? "info" : "warn",
      detail: message,
      life: 15000,
    });
  };

  return (
    <>
      <Toast ref={toast} />

      <div className="container-xxl flex-grow-1 container-p-y">
        <h4 className="fw-bold py-3 mb-4">
          <span className="text-muted fw-light">Update/</span> Account Details
        </h4>
        {/* Basic Layout & Basic with Icons */}
        <div className="row">
          {/* Basic with Icons */}
          <div className="col-xxl">
            <div className="card mb-4">
              <div className="card-header d-flex align-items-center justify-content-between">
                <h5 className="mb-0">Account Details</h5>{" "}
                <small className="text-muted float-end">
                  Fill out the form below to update your account details
                </small>
              </div>
              {state && (
                <div className="card-body">
                  <form onSubmit={handleSubmit}>
                    <div className="row mb-3">
                      <label className="col-sm-2 col-form-label">
                        Account Code
                      </label>
                      <div className="col-sm-10">
                        <div className="input-group input-group-merge">
                          <input
                            type="text"
                            className="form-control"
                            defaultValue={state.account_code}
                            aria-describedby="basic-icon-default-fullname2"
                            onChange={(e) =>
                              setState((state) => {
                                return {
                                  ...state,
                                  account_code: e.target.value,
                                };
                              })
                            }
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row mb-3">
                      <label
                        className="col-sm-2 col-form-label"
                        htmlFor="basic-icon-default-company">
                        Company Name
                      </label>
                      <div className="col-sm-10">
                        <div className="input-group input-group-merge">
                          <input
                            type="text"
                            className="form-control"
                            defaultValue={state.company_name}
                            aria-describedby="basic-icon-default-company2"
                            onChange={(e) =>
                              setState((state) => {
                                return {
                                  ...state,
                                  company_name: e.target.value,
                                };
                              })
                            }
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row mb-3">
                      <label
                        className="col-sm-2 col-form-label"
                        htmlFor="basic-icon-default-company">
                        Company Email
                      </label>
                      <div className="col-sm-10">
                        <div className="input-group input-group-merge">
                          <input
                            type="text"
                            className="form-control"
                            defaultValue={state.company_email}
                            aria-describedby="basic-icon-default-company2"
                            onChange={(e) =>
                              setState((state) => {
                                return {
                                  ...state,
                                  company_email: e.target.value,
                                };
                              })
                            }
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row mb-3">
                      <label
                        className="col-sm-2 form-label"
                        htmlFor="basic-icon-default-phone">
                        Contact Number
                      </label>
                      <div className="col-sm-10">
                        <div className="input-group input-group-merge">
                          <input
                            type="tel"
                            className="form-control phone-mask"
                            defaultValue={state.contact}
                            aria-describedby="basic-icon-default-phone2"
                            onChange={(e) =>
                              setState((state) => {
                                return {
                                  ...state,
                                  contact: e.target.value,
                                };
                              })
                            }
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row mb-3">
                      <label
                        className="col-sm-2 form-label"
                        htmlFor="basic-icon-default-phone">
                        Region
                      </label>
                      <div className="col-sm-10">
                        <div className="input-group input-group-merge">
                          <input
                            type="tel"
                            className="form-control phone-mask"
                            defaultValue={state.region}
                            aria-describedby="basic-icon-default-phone2"
                            onChange={(e) =>
                              setState((state) => {
                                return {
                                  ...state,
                                  region: e.target.value,
                                };
                              })
                            }
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row mb-3">
                      <label
                        className="col-sm-2 form-label"
                        htmlFor="basic-icon-default-phone">
                        Location
                      </label>
                      <div className="col-sm-10">
                        <div className="input-group input-group-merge">
                          <input
                            type="tel"
                            className="form-control phone-mask"
                            defaultValue={state.location}
                            aria-describedby="basic-icon-default-phone2"
                            onChange={(e) =>
                              setState((state) => {
                                return {
                                  ...state,
                                  location: e.target.value,
                                };
                              })
                            }
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row mb-3">
                      <label
                        className="col-sm-2 form-label"
                        htmlFor="basic-icon-default-phone">
                        Number of Branches
                      </label>
                      <div className="col-sm-10">
                        <div className="input-group input-group-merge">
                          <select
                            className="form-select"
                            defaultValue={state.branches}
                            onChange={(e) =>
                              setState((state) => {
                                return {
                                  ...state,
                                  branches: e.target.value,
                                };
                              })
                            }
                            aria-label="Default select example">
                            <option selected> ---- Select ---- </option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <div className="row justify-content-end py-2">
                      <div className="col-sm-10">
                        <button
                          type="submit"
                          className="btn btn-primary"
                          style={{
                            width: "100%",
                            padding: "10px",
                            fontSize: "20px",
                            fontWeight: "bold",
                          }}>
                          Update
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdateForm;
