import axios from "axios";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

const InventoryDetailsTable = () => {
  const [license, setLicense] = useState({
    licenseDetails: null,
  });
  const location = useLocation();
  // console.log(location);

  useEffect(() => {
    axios
      .post(
        `${process.env.REACT_APP_Base_Url}/inventory/get_inventory_details`,
        {
          inventory_id: location.state,
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        setLicense((license) => {
          return { ...license, licenseDetails: res.data.info };
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }, [location]);

  return (
    <>
      {license.licenseDetails && (
        <div className="container-xxl flex-grow-1 container-p-y">
          <h4 className="fw-bold py-3 mb-4">
            <span className="text-muted fw-light">Inventory /</span> Details
          </h4>
          {/* Basic Bootstrap Table */}
          <div className="card">
            <h5 className="card-header"> </h5>
            <hr />
            <div className="card-body">
              <h5 className="card-title">
                <span className="text-muted px-5">quantity : </span>
                {license.licenseDetails.quantity}
              </h5>

              <h5 className="card-title">
                <span className="text-muted px-5">delivered_by : </span>
                {license.licenseDetails.delivered_by}
              </h5>

              <h5 className="card-title">
                <span className="text-muted px-5">description : </span>
                {license.licenseDetails.description}
              </h5>

              <h5 className="card-title">
                <span className="text-muted px-5">Action by : </span>
                {license.licenseDetails.action_by}
              </h5>
            </div>
          </div>

          {/*/ Basic Bootstrap Table */}
          <hr className="my-5" />
        </div>
      )}
    </>
  );
};

export default InventoryDetailsTable;
