import Navbar from "../../Components/Navbar/Navbar";
import Footer from "../../Components/Views/Footer";
import Header from "../../Components/Views/Header";
import InventoryDetailsTable from "./inventory-Details-Tables";

const InventoryDetails = () => {
  return (
    <>
      <div className="layout-wrapper layout-content-navbar  ">
        <div className="layout-container">
          <Header />
          <div className="layout-page">
            <Navbar />
            <div className="content-wrapper">
              <InventoryDetailsTable />
              <Footer />
              <div className="content-backdrop fade" />
            </div>
          </div>
        </div>
        <div className="layout-overlay layout-menu-toggle" />
      </div>
    </>
  );
};

export default InventoryDetails;
