import axios from "axios";
import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

const AccountDetailsTable = () => {
  const [license, setLicense] = useState({
    licenseDetails: null,
  });
  const location = useLocation();
  // console.log(location);

  useEffect(() => {
    axios
      .post(
        `${process.env.REACT_APP_Base_Url}/license/license_details`,
        {
          license_id: location.state,
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        setLicense((license) => {
          return { ...license, licenseDetails: res.data.info };
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }, [location]);

  return (
    <>
      {license.licenseDetails && (
        <div className="container-xxl flex-grow-1 container-p-y">
          <h4 className="fw-bold py-3 mb-4">
            <span className="text-muted fw-light">Account /</span> Details
          </h4>
          {/* Basic Bootstrap Table */}
          <div className="card">
            <h5 className="card-header">Account Details</h5>
            <hr />

            <section className="p-1">
              <div className="row">
                <div className="col-4">
                  <h5 className="card-title">
                    <span className="text-muted px-5">Company Name : </span>
                  </h5>
                </div>
                <div className="col-8">
                  <h5 className="card-title">
                    {license.licenseDetails.company_name}
                  </h5>
                </div>
              </div>

              <section className="row">
                <div className="col-4">
                  <h5 className="card-title">
                    <span className="text-muted px-5">Company Email : </span>
                  </h5>
                </div>
                <div className="col-8">
                  <h5 className="card-title">
                    {license.licenseDetails.company_email}
                  </h5>
                </div>
              </section>

              <section className="row">
                <div className="col-4">
                  <h5 className="card-title">
                    <span className="text-muted px-5">Account Code : </span>
                  </h5>
                </div>
                <div className="col-8">
                  <h5 className="card-title">
                    {license.licenseDetails.account_code}
                  </h5>
                </div>
              </section>

              <section className="row">
                <div className="col-4">
                  <h5 className="card-title">
                    <span className="text-muted px-5">Activation Date : </span>
                  </h5>
                </div>
                <div className="col-8">
                  <h5 className="card-title">
                    {new Date(
                      license.licenseDetails.activation_date
                    ).toLocaleDateString("en-US", {
                      dateStyle: "long",
                    })}
                  </h5>
                </div>
              </section>

              <section className="row">
                <div className="col-4">
                  <h5 className="card-title">
                    <span className="text-muted px-5">Expiry Date : </span>
                  </h5>
                </div>
                <div className="col-8">
                  <h5 className="card-title">
                    {new Date(
                      license.licenseDetails.expired_date
                    ).toLocaleDateString("en-US", {
                      dateStyle: "long",
                    })}
                  </h5>
                </div>
              </section>

              <section className="row">
                <div className="col-4">
                  <h5 className="card-title">
                    <span className="text-muted px-5">
                      Days Left for Expiry :{" "}
                    </span>
                  </h5>
                </div>
                <div className="col-8">
                  <h5 className="card-title">
                    {license.licenseDetails.expiration_count}
                  </h5>
                </div>
              </section>

              <section className="row">
                <div className="col-4">
                  <h5 className="card-title">
                    <span className="text-muted px-5">Payment Date : </span>
                  </h5>
                </div>
                <div className="col-8">
                  <h5 className="card-title">
                    {new Date(
                      license.licenseDetails.payment_date
                    ).toLocaleDateString("en-US", {
                      dateStyle: "long",
                    })}
                  </h5>
                </div>
              </section>

              <section className="row">
                <div className="col-4">
                  <h5 className="card-title">
                    <span className="text-muted px-5">Contact Number : </span>
                  </h5>
                </div>
                <div className="col-8">
                  <h5 className="card-title">
                    {license.licenseDetails.contact}
                  </h5>
                </div>
              </section>

              <section className="row">
                <div className="col-4">
                  <h5 className="card-title">
                    <span className="text-muted px-5">Location : </span>
                  </h5>
                </div>
                <div className="col-8">
                  <h5 className="card-title">
                    {license.licenseDetails.region} -{" "}
                    {license.licenseDetails.location}
                  </h5>
                </div>
              </section>
              <section className="row">
                <div className="col-4">
                  <h5 className="card-title">
                    <span className="text-muted px-5">Branche(s) : </span>
                  </h5>
                </div>
                <div className="col-8">
                  <h5 className="card-title">
                    {license.licenseDetails.branches} - Branche(s)
                  </h5>
                </div>
              </section>

              <section className="row">
                <div className="col-4">
                  <h5 className="card-title">
                    <span className="text-muted px-5">Comment : </span>
                  </h5>
                </div>
                <div className="col-8">
                  <h5 className="card-title">
                    {license.licenseDetails.comment}
                  </h5>
                </div>
              </section>

              <section className="row">
                <div className="col-4">
                  <h5 className="card-title">
                    <span className="text-muted px-5">Status : </span>
                  </h5>
                </div>
                <div className="col-8">
                  <h5 className="card-title">
                    {license.licenseDetails.license_status}
                  </h5>
                </div>
              </section>

              <section className="row">
                <div className="col-4">
                  <h5 className="card-title">
                    <span className="text-muted px-5">Recent Action by : </span>
                  </h5>
                </div>
                <div className="col-8">
                  <h5 className="card-title">
                    {license.licenseDetails.action_by} on -{" "}
                    {license.licenseDetails &&
                      new Date(
                        license.licenseDetails.updatedAt
                      ).toLocaleDateString("en-US", {
                        dateStyle: "long",
                      })}{" "}
                    , Time -{" "}
                    {license.licenseDetails &&
                      license.licenseDetails.updatedAt.slice(11, 16)}
                  </h5>
                </div>
              </section>

              <section className="row">
                <div className="col-4">
                  <h5 className="card-title">
                    <span className="text-muted px-5">Date Created : </span>
                  </h5>
                </div>
                <div className="col-8">
                  <h5 className="card-title">
                    {license.licenseDetails &&
                      new Date(
                        license.licenseDetails.createdAt
                      ).toLocaleDateString("en-US", {
                        dateStyle: "long",
                      })}{" "}
                    , Time -{" "}
                    {license.licenseDetails &&
                      license.licenseDetails.createdAt.slice(11, 16)}
                  </h5>
                </div>
              </section>

              <section className="row">
                <div className="col-4">
                  <h5 className="card-title">
                    <span className="text-muted px-5">Date Updated : </span>
                  </h5>
                </div>
                <div className="col-8">
                  <h5 className="card-title">
                    {license.licenseDetails &&
                      new Date(
                        license.licenseDetails.updatedAt
                      ).toLocaleDateString("en-US", {
                        dateStyle: "long",
                      })}{" "}
                    , Time -{" "}
                    {license.licenseDetails &&
                      license.licenseDetails.updatedAt.slice(11, 16)}
                  </h5>
                </div>
              </section>
              <div className="row py-5 m-2">
                <div className="col-12" style={{ width: "100%" }}>
                  <Link
                    to="/edit"
                    style={{
                      width: "100%",
                      padding: "10px",
                      fontSize: "20px",
                      fontWeight: "bold",
                    }}
                    state={license.licenseDetails._id}
                    className="btn btn-primary btn-lg btn-block">
                    Edit
                  </Link>
                </div>
              </div>
            </section>
          </div>
        </div>
      )}
    </>
  );
};

export default AccountDetailsTable;
