import Header from "../../Views/Header";
import Footer from "../../Views/Footer";
import Navbar from "../../Navbar/Navbar";
import UserContent from "./UserContent";

const UserDetails = () => {
  return (
    <>
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          <Header />
          <div className="layout-page">
            <Navbar />
            <div className="content-wrapper">
              <UserContent />
              <Footer />
              <div className="content-backdrop fade" />
            </div>
          </div>
        </div>
        <div className="layout-overlay layout-menu-toggle" />
      </div>
    </>
  );
};

export default UserDetails;
