import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import LoginStyles from "./Login.module.css";
import { Toast } from "primereact/toast";
import { Input } from "antd";
import axios from "axios";

const Login = () => {
  const toast = useRef<Toast>(null);
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [remember, setRemember] = useState(false);
  const [loader, setLoader] = useState(true);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setLoader(false);

    axios
      .post(`${process.env.REACT_APP_Base_Url}/admin/login`, {
        email,
        password,
      })
      .then((res) => {
        show(res.data.message, res.data.status);

        if (res.data.status === 200) {
          sessionStorage.setItem("token", res.data.token);
          sessionStorage.setItem("role", res.data.info.user_role);
          sessionStorage.setItem("user_id", res.data.info._id);

          setTimeout(() => {
            navigate("/");
          }, 2000);
        }
        setLoader(true);
      })
      .catch((err) => {
        show(err.data.message, err.data.status);
        setLoader(true);
      });
  };

  // ! code did not work as expected
  // const rememberMe = (checked: string) => {
  //   if (checked) {
  //     // console.log(checked);
  //     localStorage.setItem("email", email);
  //     localStorage.setItem("password", password);
  //   } else {
  //     localStorage.clear();
  //   }
  // };

  // todo:@ call toast
  const show = (message: string, flag: number) => {
    toast.current?.show({
      severity: flag === 200 ? "success" : "error",
      summary: flag === 200 ? "success" : "error",
      detail: message,
      life: 15000,
    });
  };

  //todo:@ check if user has credentials in local storage and set them to state
  useEffect(() => {
    const storedEmail = localStorage.getItem("email");
    const storedPassword = localStorage.getItem("password");
    if (storedEmail && storedPassword) {
      setEmail(storedEmail);
      setPassword(storedPassword);
      setRemember(!remember);
    }
  }, [remember]);

  return (
    <>
      <Toast ref={toast} />

      <div className="container-xxl">
        <div
          className="authentication-wrapper authentication-basic container-p-y"
          style={{ height: "100vh" }}>
          <div className="authentication-inner" id={LoginStyles.motherDisplay}>
            <div className="card" id={LoginStyles.innerDisplay}>
              <div className="card-body">
                <h4 className="mb-2">Welcome to Sanfiley ! </h4>
                <p className="mb-4">
                  Please sign-in to your account and start the adventure
                </p>
                <form className="mb-3" onSubmit={handleSubmit}>
                  <div className="mb-3">
                    <label htmlFor="email" className="form-label">
                      Email
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      required
                      placeholder="Enter your email"
                      defaultValue={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="mb-5 form-password-toggle">
                    <div className="d-flex justify-content-between">
                      <label className="form-label" htmlFor="password">
                        Password
                      </label>
                    </div>
                    <div className="input-group input-group-merge">
                      <Input.Password
                        placeholder="input password"
                        className="py-2"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        iconRender={(visible) =>
                          visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                        }
                      />
                    </div>
                  </div>
                  {/* {password.length > 0 && email.length > 0 ? (
                    <div className="mb-3">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="remember-me"
                          defaultChecked={remember}
                          onClick={(e) =>
                            rememberMe(
                              (e.target as HTMLInputElement).checked.toString()
                            )
                          }
                        />
                        <label
                          className="form-check-label"
                          htmlFor="remember-me">
                          Remember Me
                        </label>
                      </div>
                    </div>
                  ) : null} */}

                  {loader ? (
                    <div className="mb-3">
                      <button
                        className="btn btn-primary d-grid w-100"
                        type="submit">
                        Sign in
                      </button>
                    </div>
                  ) : (
                    <div className="mb-3">
                      <button
                        disabled
                        className="btn btn-primary d-grid w-100"
                        type="submit">
                        loading ...
                      </button>
                    </div>
                  )}
                </form>
                <p className="text-center">
                  <span>New on our platform ? </span> {""}
                  <a href="register">
                    <span style={{ color: "green" }}>register</span>
                  </a>{" "}
                  |
                  <a href="/forgot-password">
                    <span style={{ color: "red" }}> forgot password</span>
                  </a>
                </p>
              </div>
            </div>
            {/* /Register */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
