import { InputSwitch } from "primereact/inputswitch";
import React, { useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { Toast } from "primereact/toast";
import axios from "axios";

const Content = () => {
  const location = useLocation();
  const toast = useRef<Toast>(null);
  console.log(location);
  const user = location.state.data;
  const [read, setRead] = useState(user.user_read);
  const [create, setCreate] = useState(user.user_create);
  const [update, setUpdate] = useState(user.user_update);
  const [deleteItem, setDelete] = useState(user.user_delete);
  // const [modify, setModify] = useState(user.privileges.modify);

  const handleSubmit = () => {
    axios
      .post(
        `${process.env.REACT_APP_Base_Url}/admin/modify_privileges`,
        {
          user_id: user._id,
          user_read: read,
          user_create: create,
          user_update: update,
          user_delete: deleteItem,
          // user_modify: modify,
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        show(res.data.message, res.data.status);
      })
      .catch((err) => console.log(err));
  };

  // todo:@ call primereact toast
  const show = (message: string, flag: number) => {
    toast.current?.show({
      severity: flag === 200 ? "success" : "warn",
      summary: flag === 200 ? "success" : "warn",
      detail: message,
      life: 15000,
    });
  };

  return (
    <>
      <Toast ref={toast} />

      <div className="container-xxl flex-grow-1 container-p-y">
        <div className="row">
          <div className="col-md-12">
            <div className="card mb-4">
              <h5 className="card-header">Profile Details</h5>
              {/* Account */}

              <hr className="my-0" />
              <div className="card-body">
                {user && (
                  <form>
                    <div className="row">
                      <div className="mb-3 col-md-6">
                        <label htmlFor="firstName" className="form-label">
                          First Name
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          name="firstName"
                          placeholder={user.first_name}
                        />
                      </div>
                      <div className="mb-3 col-md-6">
                        <label htmlFor="lastName" className="form-label">
                          Last Name
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          name="lastName"
                          placeholder={user.last_name}
                        />
                      </div>
                      <div className="mb-3 col-md-6">
                        <label htmlFor="email" className="form-label">
                          E-mail
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          name="email"
                          placeholder={user.email}
                        />
                      </div>
                      <div className="mb-3 col-md-6">
                        <label htmlFor="organization" className="form-label">
                          role
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="role"
                          placeholder={user.user_role}
                        />
                      </div>
                    </div>
                  </form>
                )}
              </div>
              {/* /Account */}
            </div>

            <div className="card">
              <h5 className="card-header">Account Privileges</h5>
              <div className="card-body">
                <div className="mb-3 col-12">
                  <div className="alert alert-warning">
                    <h6 className="alert-heading fw-bold mb-1">
                      Are you sure you want to update this account's privileges?
                    </h6>
                  </div>
                </div>
                <div className="row">
                  <div className="mb-3 col-md-3">
                    <div
                      className="card justify-content-center p-2"
                      style={{ display: "flex" }}>
                      <label>Read</label>

                      <InputSwitch
                        checked={read}
                        onChange={(e) => setRead(e.value)}
                      />
                    </div>
                  </div>
                  <div className="mb-3 col-md-3">
                    <div
                      className="card justify-content-center p-2"
                      style={{ display: "flex" }}>
                      <label>Create</label>

                      <InputSwitch
                        checked={create}
                        onChange={(e) => setCreate(e.value)}
                      />
                    </div>
                  </div>

                  <div className="mb-3 col-md-3">
                    <div
                      className="card justify-content-center p-2"
                      style={{ display: "flex" }}>
                      <label>Delete</label>

                      <InputSwitch
                        checked={deleteItem}
                        onChange={(e) => setDelete(e.value)}
                      />
                    </div>
                  </div>

                  <div className="mb-3 col-md-3">
                    <div
                      className="card justify-content-center p-2"
                      style={{ display: "flex" }}>
                      <label>Update</label>

                      <InputSwitch
                        checked={update}
                        onChange={(e) => setUpdate(e.value)}
                      />
                    </div>
                  </div>

                  {/* <div className="mb-3 col-md-12">
                    <div
                      className="card justify-content-center p-2"
                      style={{ display: "flex" }}>
                      <label>Modify</label>

                      <InputSwitch
                        checked={modify}
                        onChange={(e) => setModify(e.value)}
                      />
                    </div>
                  </div> */}

                  <button
                    type="submit"
                    className="btn btn-info mt-5 p-2"
                    onClick={handleSubmit}>
                    Update Privileges
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Content;
