import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { updateUsersChange } from "../../Redux/slicies/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Link } from "react-router-dom";
import axios from "axios";

const UserTable = () => {
  const dispatch = useDispatch();
  const toast = useRef<Toast>(null);

  interface RootState {
    user: {
      users_change: any;
    };
  }

  const { users_change } = useSelector((state: RootState) => state.user);

  useEffect(() => {
    const tk = sessionStorage.getItem("token");

    if (tk) {
      axios
        .get(`${process.env.REACT_APP_Base_Url}/admin/users`, {
          headers: {
            Authorization: `Bearer ${tk}`,
          },
        })
        .then((res) => {
          console.log(res.data);
          // setData(res.data.users);
          if (res.data.status === 200) {
            // the below line is the one that updates the state
            dispatch(updateUsersChange(res.data.info));
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [dispatch]);

  // todo:@ call primereact confirm dialog
  const reject = () => {
    show("You have rejected", 400);
  };

  // todo:@ call primereact confirm dialog
  const confirm1 = (id: string) => {
    confirmDialog({
      message: "Do you want to deactivate this record?",
      header: "Deactivate Confirmation",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-danger",
      accept: () => Deactivate(id),
      reject,
    });
  };

  const Deactivate = (id: string) => {
    axios
      .post(
        `${process.env.REACT_APP_Base_Url}/admin/delete_users`,
        {
          user_id: id,
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        show(res.data.message, res.data.status);

        const tk = sessionStorage.getItem("token");

        if (tk) {
          axios
            .get(`${process.env.REACT_APP_Base_Url}/admin/users`, {
              headers: {
                Authorization: `Bearer ${tk}`,
              },
            })
            .then((res) => {
              if (res.data.status === 200) {
                // console.log(res.data);
                // the below line is the one that updates the state
                dispatch(updateUsersChange(res.data.info));
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      })
      .catch((err) => console.log(err));
  };

  // todo:@ call primereact toast
  const show = (message: string, flag: number) => {
    toast.current?.show({
      severity: flag === 200 ? "success" : "warn",
      summary: flag === 200 ? "success" : "warn",
      detail: message,
      life: 15000,
    });
  };

  return (
    <>
      <Toast ref={toast} />
      <ConfirmDialog />

      <div className="container-xxl flex-grow-1 container-p-y">
        <h4 className="fw-bold py-3 mb-4">
          <span className="text-muted fw-light">Tables /</span> All Users Table
        </h4>
        {/* Basic Bootstrap Table */}
        <div className="card">
          <h5 className="card-header">All Data</h5>
          <div className="table-responsive text-nowrap">
            <table className="table">
              <thead>
                <tr>
                  <th>User Name</th>
                  <th>Email </th>
                  <th>Status</th>
                  <th>Details</th>
                  <th>Action</th>
                </tr>
              </thead>

              {users_change && users_change.length !== undefined ? (
                <tbody className="table-border-bottom-0">
                  {users_change &&
                    users_change.map((user: any) => (
                      <tr key={user?._id}>
                        <td>
                          <i className="fab fa-angular fa-lg text-danger me-3" />{" "}
                          <strong>
                            {user?.first_name} {""} {user?.last_name}
                          </strong>
                        </td>
                        <td>{user?.email}</td>

                        <td>
                          <span className="badge bg-label-primary me-1">
                            {user?.user_role}
                          </span>
                        </td>

                        <td>
                          <Link
                            to={"/user-details"}
                            state={{ data: user }}
                            className="btn btn-info p-2">
                            <i className="fa fa-edit fa-lg mr-2" />
                            View details
                          </Link>
                        </td>

                        <td>
                          <button
                            type="button"
                            className="btn btn-danger p-2"
                            onClick={(e) => confirm1(user?._id)}>
                            <i className="fa fa-edit fa-lg mr-2" />
                            Delete
                          </button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              ) : (
                <tbody>
                  <tr>
                    <th></th>
                    <td></td>
                    <td className="py-3">No Records Found</td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                </tbody>
              )}
            </table>
          </div>
          <div className="card-footer">
            <hr />
            <div className="d-flex justify-content-between">
              {users_change && <p>Total : {users_change?.length}</p>}
            </div>
          </div>
        </div>
        {/*/ Basic Bootstrap Table */}
        <hr className="my-5" />
      </div>
    </>
  );
};

export default UserTable;
