import Content from "./Content";
import Footer from "../../Components/Views/Footer";
import Header from "../../Components/Views/Header";
import Navbar from "../../Components/Navbar/Navbar";
import Overlay from "../../Components/Overlay/Overlay";

const Dashboard = () => {
  return (
    <>
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          <Header />

          <div className="layout-page">
            <Navbar />

            <div className="content-wrapper">
              <Content />

              <Footer />

              <div className="content-backdrop fade" />
            </div>
          </div>
        </div>
        <Overlay />
      </div>
    </>
  );
};

export default Dashboard;
