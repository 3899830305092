import React from "react";
import A from "../../assets/img/err.jpeg";

const Error = () => {
  return (
    <>
      <div className="container-xxl container-p-y text-center">
        <div className="misc-wrapper">
          <div className="mt-3">
            <img src={A} alt="page-misc-error-light" className="img-fluid" />
          </div>
          <br />
          <h2 className="mb-2 mx-2">Page Not Found :(</h2>
          <p className="mb-4 mx-2">
            Oops! 😖 The requested URL was not found on this server.
          </p>
          <br />
          <a href="/" className="btn btn-primary">
            Back to home
          </a>
        </div>
      </div>
    </>
  );
};

export default Error;
