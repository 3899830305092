import { useNavigate } from "react-router-dom";
import Styles from "./Register.module.css";
import { useRef, useState } from "react";
import { Toast } from "primereact/toast";
import axios from "axios";

const Register = () => {
  const navigate = useNavigate();
  const [user_role, setRole] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [last_name, setLastName] = useState("");
  const [first_name, setFirstName] = useState("");
  const [confirm_password, setConfirmPassword] = useState("");
  const toast = useRef<Toast>(null);

  const submitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log(first_name, last_name, email, password, user_role);

    if (password !== confirm_password) {
      show("Passwords don't match", 400);
    } else {
      await axios
        .post(`${process.env.REACT_APP_Base_Url}/admin/register`, {
          first_name,
          last_name,
          email,
          password,
          user_role,
        })
        .then((response) => {
          show(response.data.message, response.data.status);
          if (response.data.status === 201) {
            setTimeout(() => {
              navigate("/login");
            }, 2000);
          }
        });
    }
  };

  // todo:@ call toast
  const show = (message: string, flag: number) => {
    toast.current?.show({
      severity: flag === 201 ? "success" : "error",
      summary: flag === 201 ? "success" : "error",
      detail: message,
      life: 15000,
    });
  };

  return (
    <>
      <Toast ref={toast} />

      <div className="container-xxl">
        <div className="authentication-wrapper authentication-basic container-p-y">
          <div className="authentication-inner">
            {/* Register Card */}
            <div className={Styles.card}>
              <div className="card-body">
                <h4 className="mb-2">Adventure starts here 🚀</h4>
                <p className="mb-4">Make your app management easy and fun!</p>
                <form className="mb-3" onSubmit={submitHandler}>
                  <div className="mb-3">
                    <label htmlFor="first name" className="form-label">
                      first name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="first name"
                      placeholder="Enter your First Name"
                      required
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                  </div>

                  <div className="mb-3">
                    <label htmlFor="last Name" className="form-label">
                      Last name
                    </label>
                    <input
                      type="text"
                      required
                      className="form-control"
                      name="Last name"
                      placeholder="Enter your Last Name"
                      onChange={(e) => setLastName(e.target.value)}
                    />
                  </div>

                  <div className="mb-3">
                    <label htmlFor="email" className="form-label">
                      Email
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      required
                      name="email"
                      placeholder="Enter your email"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>

                  <div className="mb-3 form-password-toggle">
                    <label className="form-label" htmlFor="password">
                      Password
                    </label>
                    <div className="input-group input-group-merge">
                      <input
                        type="password"
                        className="form-control"
                        required
                        name="password"
                        placeholder="············"
                        aria-describedby="password"
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <span className="input-group-text cursor-pointer">
                        <i className="bx bx-hide" />
                      </span>
                    </div>
                  </div>

                  <div className="mb-3 form-password-toggle">
                    <label className="form-label" htmlFor="confirm_password">
                      Confirm Password
                    </label>
                    <div className="input-group input-group-merge">
                      <input
                        type="password"
                        className="form-control"
                        required
                        name="confirm_password"
                        placeholder="············"
                        aria-describedby="confirm_password"
                        onChange={(e) => setConfirmPassword(e.target.value)}
                      />
                      <span className="input-group-text cursor-pointer">
                        <i className="bx bx-hide" />
                      </span>
                    </div>
                  </div>

                  <div className="mb-3 form-password-toggle">
                    <label htmlFor="role" className="form-label">
                      Role
                    </label>
                    <select
                      id="role"
                      className="select2 form-select"
                      name="role"
                      required
                      onChange={(e) => setRole(e.target.value)}>
                      <option>Select Role</option>
                      <option value={"staff"}>Staff</option>
                      <option value={"admin"}>Admin</option>
                    </select>
                  </div>

                  <div className="mb-3">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="terms-conditions"
                        name="terms"
                        required
                      />
                      <label
                        className="form-check-label"
                        htmlFor="terms-conditions">
                        I agree to
                        <a href="#.">privacy policy &amp; terms</a>
                      </label>
                    </div>
                  </div>

                  <button
                    className="btn btn-primary d-grid w-100"
                    type="submit">
                    Sign up
                  </button>
                </form>

                <p className="text-center">
                  <span>Already have an account ? </span>
                  <a href="login">
                    <span>Sign in </span>
                  </a>
                </p>
              </div>
            </div>
            {/* Register Card */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Register;
