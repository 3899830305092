import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Toast } from "primereact/toast";
import axios from "axios";

const InventoryForm = () => {
  const navigate = useNavigate();
  const [quantity, setQuantity] = useState(0);
  const [delivered_by, setDeliveredBy] = useState("");
  const [date_delivered, setDateDelivered] = useState("");
  const [description, setDescription] = useState("");
  const toast = useRef<Toast>(null);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    axios
      .post(
        `${process.env.REACT_APP_Base_Url}/inventory/post_inventory`,
        {
          quantity,
          delivered_by,
          date_delivered,
          description,
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        show(res.data.message, res.data.status);

        if (res.data.status === 201) {
          setTimeout(() => {
            navigate("/inventory");
          }, 2000);
        }
      })
      .catch((err) => console.log(err));
  };

  // todo:@ call toast
  const show = (message: string, flag: number) => {
    toast.current?.show({
      severity: flag === 201 ? "success" : "error",
      summary: flag === 201 ? "success" : "error",
      detail: message,
      life: 15000,
    });
  };

  return (
    <>
      <Toast ref={toast} />

      <div className="container-xxl flex-grow-1 container-p-y">
        <h4 className="fw-bold py-3 mb-4">
          <span className="text-muted fw-light"></span> Add Inventory
        </h4>
        {/* Basic Layout & Basic with Icons */}
        <div className="row">
          {/* Basic with Icons */}
          <div className="col-xxl">
            <div className="card mb-4">
              <div className="card-header d-flex align-items-center justify-content-between">
                <h5 className="mb-0">Basic Data</h5>{" "}
                <small className="text-muted float-end">
                  Fill out the form below to add a new data
                </small>
              </div>
              <div className="card-body">
                <form onSubmit={handleSubmit}>
                  <div className="row mb-3">
                    <label className="col-sm-2 col-form-label">
                      Item(s) Delivered
                    </label>
                    <div className="col-sm-10">
                      <div className="input-group input-group-merge">
                        <input
                          type="number"
                          className="form-control"
                          placeholder="eg. 69 item(s)"
                          required
                          aria-describedby="basic-icon-default"
                          onChange={(e) => setQuantity(e.target.valueAsNumber)}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row mb-3">
                    <label
                      className="col-sm-2 col-form-label"
                      htmlFor="basic-icon-default-company">
                      Who Delivered It?
                    </label>
                    <div className="col-sm-10">
                      <div className="input-group input-group-merge">
                        <input
                          type="text"
                          className="form-control"
                          required
                          placeholder="Sanfiley Microsystem"
                          aria-describedby="basic-icon-default-company2"
                          onChange={(e) => setDeliveredBy(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row mb-3">
                    <label
                      className="col-sm-2 col-form-label"
                      htmlFor="basic-icon-default-email">
                      Date Delivered
                    </label>
                    <div className="col-sm-10">
                      <div className="input-group input-group-merge">
                        <input
                          type="date"
                          className="form-control"
                          required
                          placeholder="Activation Date"
                          aria-describedby="basic-icon-default-email2"
                          onChange={(e) => setDateDelivered(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>

                  {/* <div className="row mb-3">
                    <label
                      className="col-sm-2 form-label"
                      htmlFor="basic-icon-default-phone">
                      Expiration Date
                    </label>
                    <div className="col-sm-10">
                      <div className="input-group input-group-merge">
                        <input
                          type="date"
                          className="form-control phone-mask"
                          required
                          placeholder="Expiration Date"
                          aria-describedby="basic-icon-default-phone2"
                          onChange={(e) => setExpirationDate(e.target.value)}
                        />
                      </div>
                    </div>
                  </div> */}

                  {/* <div className="row mb-3">
                    <label
                      className="col-sm-2 form-label"
                      htmlFor="basic-icon-default-phone">
                      Payment Date
                    </label>
                    <div className="col-sm-10">
                      <div className="input-group input-group-merge">
                        <input
                          type="date"
                          className="form-control phone-mask"
                          placeholder="Payment Date"
                          aria-describedby="basic-icon-default-phone2"
                          onChange={(e) => setPaymentDate(e.target.value)}
                        />
                      </div>
                    </div>
                  </div> */}

                  <div className="row mb-3">
                    <label
                      className="col-sm-2 form-label"
                      htmlFor="basic-icon-default-phone">
                      Comment
                    </label>
                    <div className="col-sm-10">
                      <div className="input-group input-group-merge">
                        <textarea
                          className="form-control"
                          placeholder="Comment"
                          onChange={(e) =>
                            setDescription(e.target.value)
                          }></textarea>
                      </div>
                    </div>
                  </div>

                  <div className="row justify-content-end">
                    <div className="col-sm-10">
                      <button type="submit" className="btn btn-primary">
                        Add
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InventoryForm;
