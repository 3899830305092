import axios from "axios";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";

export type Inventory = {
  _id: string;
  quantity: number;
  delivered_by: string;
  date_delivered: string;
  description: string;
};

const InventoryTable = () => {
  const [status_change, setStatusChange] = useState([]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_Base_Url}/inventory/get_all_inventory`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status === 200) {
          setStatusChange(res.data.info);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <div className="container-xxl flex-grow-1 container-p-y">
        <h4 className="fw-bold py-3 mb-4">
          <span className="text-muted fw-light"></span> Inventory Table
        </h4>
        {/* Basic Bootstrap Table */}
        <div className="card">
          <h5 className="card-header"> </h5>
          <div className="table-responsive text-nowrap">
            <table className="table">
              <thead>
                <tr>
                  <th>#</th>
                  <th>No. of Items</th>
                  <th>Date Delivered</th>
                  <th>Delivered By</th>
                  <th>Actions</th>
                </tr>
              </thead>

              {status_change &&
              status_change.length !== 0 &&
              status_change.length !== undefined ? (
                <tbody className="table-border-bottom-0">
                  {status_change &&
                    status_change.map((license: Inventory) => (
                      <tr key={license?._id}>
                        <td>#</td>
                        <td>
                          <i className="fab fa-angular fa-lg text-danger me-3" />{" "}
                          <strong>{license?.quantity}</strong>
                        </td>
                        <td>{license?.delivered_by}</td>
                        <td>{license?.date_delivered}</td>

                        <td>
                          <Link
                            to={`/view-inventory-details`}
                            className="btn btn-sm btn-primary me-2"
                            state={license?._id}>
                            View Details
                          </Link>
                        </td>
                      </tr>
                    ))}
                </tbody>
              ) : (
                <tbody>
                  <tr>
                    <th></th>
                    <td></td>
                    <td className="py-3">No Records Found</td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                </tbody>
              )}
            </table>
          </div>
        </div>
        {/*/ Basic Bootstrap Table */}
        <hr className="my-5" />
      </div>
    </>
  );
};

export default InventoryTable;
