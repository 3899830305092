import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import axios from "axios";
import { Tag } from "primereact/tag";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";

export default function PaginatorBasicDemo() {
  const [customers, setCustomers] = useState([]);
  const [statuses] = useState(["active", "inactive"]);
  const dt = useRef(null);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_Base_Url}/all_license`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status === 200) {
          console.log(res.data.license);
          setCustomers(res.data.license);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  const cols = [
    { field: "company_name", header: "Company Name" },
    { field: "activation_date", header: "Activation Date" },
    { field: "expired_date", header: "Expiration Date" },
    { field: "status", header: "Status" },
    { field: "expiration_count", header: "Days Left" },
  ];

  const exportColumns = cols.map((col) => ({
    title: col.header,
    dataKey: col.field,
  }));

  const exportCSV = (selectionOnly) => {
    dt.current.exportCSV({ selectionOnly });
  };

  const exportPdf = () => {
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const doc = new jsPDF.default(0, 0);

        doc.autoTable(exportColumns, customers);
        doc.save("customers.pdf");
      });
    });
  };

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(customers);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      saveAsExcelFile(excelBuffer, "customers");
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(
          data,
          fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
        );
      }
    });
  };

  const header = (
    <div
      style={{ display: "flex" }}
      className="align-items-center justify-content-end gap-2">
      <Button
        type="button"
        icon="pi pi-file"
        rounded
        onClick={() => exportCSV(false)}
        data-pr-tooltip="CSV"
      />
      <Button
        type="button"
        icon="pi pi-file-excel"
        severity="success"
        rounded
        onClick={exportExcel}
        data-pr-tooltip="XLS"
      />
      <Button
        type="button"
        icon="pi pi-file-pdf"
        severity="warning"
        rounded
        onClick={exportPdf}
        data-pr-tooltip="PDF"
      />
    </div>
  );

  const getSeverity = (value) => {
    switch (value) {
      case "active":
        return "success";

      case "inactive":
        return "danger";

      default:
        return null;
    }
  };

  const statusEditor = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={statuses}
        onChange={(e) => options.editorCallback(e.value)}
        placeholder="Select a Status"
        itemTemplate={(option) => {
          return <Tag value={option} severity={getSeverity(option)}></Tag>;
        }}
      />
    );
  };

  const statusBodyTemplate = (rowData) => {
    return (
      <Tag value={rowData.status} severity={getSeverity(rowData.status)}></Tag>
    );
  };

  const sliceActivationDate = (date) => {
    return <>{date?.activation_date.slice(0, 10)}</>;
  };

  const sliceDeactivationDate = (date) => {
    return <>{date?.expired_date.slice(0, 10)}</>;
  };

  return (
    <div className="container container-p-y">
      <div className="card">
        <DataTable
          value={customers}
          header={header}
          paginator
          rows={25}
          rowsPerPageOptions={[5, 10, 25, 50]}
          tableStyle={{ minWidth: "50rem" }}>
          <Column field="company_name" header="Company Name"></Column>
          <Column body={sliceActivationDate} header="Activation Date"></Column>
          <Column
            body={sliceDeactivationDate}
            header="Expiration Date"></Column>
          <Column
            header="Status"
            body={statusBodyTemplate}
            editor={(options) => statusEditor(options)}
            className="stat"></Column>
          <Column field="expiration_count" header="Days Left"></Column>
        </DataTable>
      </div>
    </div>
  );
}
