const Overlay = () => {
  const sidebarToggle = () => {
    document.querySelector("html").classList.toggle("layout-menu-expanded");
  };
  return (
    <>
      <div
        className="layout-overlay layout-menu-toggle"
        onClick={() => sidebarToggle()}
      />
    </>
  );
};

export default Overlay;
